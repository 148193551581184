<template>
  <div>
    <div class="uk-card uk-card-default uk-padding-small uk-border-rounded">
      <div class="uk-flex uk-flex-between">
        <div class="uk-text-large">Mi perfil</div>
        <button
          class="uk-button uk-button-default"
          uk-icon="pencil"
          href="#edit-user-modal"
          uk-toggle
          @click="setEditMode"
        ></button>
      </div>
      <div class="flex items-center">
        <div class="flex flex-col mr-6">
          <div v-if="imageData">
            <img :src="imageData" class="rounded-full w-64 h-64" />
          </div>

          <div class="mt-4">
            <div class="flex justify-center mt-4" v-if="showEditAvatar">
              <div>
                <button
                  class="bg-green-400 text-white rounded-full p-2"
                  @click="uploadAvatar()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </button>
              </div>
              <div>
                <button
                  class="bg-red-400 text-white rounded-full p-2 ml-2"
                  @click="de"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div v-else>
              <label
                for="avatar-input"
                class="flex items-center bg-gray-200 justify-center rounded-full px-4 py-2 cursor-pointer hover:bg-gray-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>

                <strong class="text-xs mt-1">Cambiar Foto de perfil</strong>
              </label>
              <input
                id="avatar-input"
                type="file"
                @change="onImageChange"
                class="hidden"
                accept="image/*"
              />
            </div>
          </div>
        </div>

        <div>
          <div class="uk-text-bold uk-margin-small-top">Nombre</div>
          <div>{{ authUser.first_name }} {{ authUser.last_name }}</div>
          <div class="uk-text-bold uk-margin-small-top">Email</div>
          <div>{{ authUser.email }}</div>

          <div v-if="authUser.job_title">
            <div class="uk-text-bold uk-margin-small-top">Puesto</div>
            <div>{{ authUser.job_title }}</div>
          </div>

          <div v-if="authUser.phone">
            <div class="uk-text-bold uk-margin-small-top">Teléfono</div>
            <div>{{ authUser.phone }}</div>
          </div>

          <div v-if="authUser.mobile_phone">
            <div class="uk-text-bold uk-margin-small-top">Teléfono móvil</div>
            <div>{{ authUser.mobile_phone }}</div>
          </div>

          <div class="uk-text-bold uk-margin-small-top">
            Notificaciones de cambios de stock o precios de artículos en carrito
          </div>
          <div v-if="authUser.cart_notifications">Activadas</div>
          <div v-else>Desactivadas</div>
        </div>
      </div>
    </div>

    <div id="edit-user-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">Editar perfil</div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider
            name="Nombres"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Nombres</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Nombres"
              v-model="model.first_name"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Apellidos"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Apellidos</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Apellidos"
              v-model="model.last_name"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="Puesto" v-slot="{ errors }">
            <div class="uk-margin-top">Puesto</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Puesto"
              v-model="model.job_title"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">Email</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Email"
              v-model="model.email"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="Teléfono" v-slot="{ errors }">
            <div class="uk-margin-top">Teléfono</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Teléfono"
              v-model="model.phone"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="Teléfono móvil" v-slot="{ errors }">
            <div class="uk-margin-top">Teléfono móvil</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Teléfono móvil"
              v-model="model.mobile_phone"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="type" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">
              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  v-model="model.cart_notifications"
                />
                Notificaciones de cambios de stock o precios de artículos en
                carrito
              </label>
              <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
            </div>
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="invalid || isLoading"
              @click="update"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else> Actualizar </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <AddressCrud />
  </div>
</template>

<script>
import UIkit from "uikit";
import AddressCrud from "../Utils/AddressCrud";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProfileIndex",

  components: {
    AddressCrud,
  },

  data() {
    return {
      mode: "new",
      model: {},
      isLoadingAddresses: false,
      salesmen: [],
      isLoadingSalesmen: false,
      selectedAddress: null,
      isFetching: false,
      isLoading: false,
      imageData: null,
      showEditAvatar: false,
    };
  },

  mounted() {
    this.resetModel();
    this.getAvatar();
  },

  computed: {
    ...mapGetters(["authUser"]),
  },

  methods: {
    ...mapActions(["authFetchUser"]),
    resetModel() {
      this.model = {
        first_name: this.authUser.first_name,
        last_name: this.authUser.last_name,
        email: this.authUser.email,
        phone: this.authUser.phone,
        mobile_phone: this.authUser.mobile_phone,
        job_title: this.authUser.job_title,
        cart_notifications: this.authUser.cart_notifications,
      };
    },
    setEditMode() {
      this.resetModel();
    },
    update() {
      this.isLoading = true;
      this.axios
        .put("/user", this.model)
        .then(() => {
          this.authFetchUser();
          UIkit.modal("#edit-user-modal").hide();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getAvatar() {
      this.axios
        .get("/avatar")
        .then((response) => {
          this.imageData = response.data.image;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    onImageChange(e) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(file);
        this.showEditAvatar = true;
      }
    },

    cancelUpdateAvatar() {
      this.showEditAvatar = false;
      this.imageData = null;
      this.getAvatar();
    },

    uploadAvatar() {
      this.axios
        .post("/avatar", { avatar: this.imageData })
        .then((response) => {
          this.showEditAvatar = false;
          console.log("response", response);
        });
    },
  },
};
</script>
